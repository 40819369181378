import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row, Table } from "react-bootstrap";
import Driver from "../analysis/Decompose";
import { DateRangePicker, Checkbox, SelectPicker } from "rsuite";
import 'rsuite/dist/rsuite.min.css';
import format from 'date-fns/format';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCheckSquare, faExclamationTriangle, faTimesSquare } from "@fortawesome/free-solid-svg-icons";
import {quickSelectionDateOptions, prognosisOptions} from '../../utils/dateRanges';
import Season from "../analysis/Season";
import Break from "../analysis/Break";
import { useLocation, useNavigate } from "react-router-dom";
import Notification from "../Notification";

const ModelInput = ()=>{
    const location = useLocation();
    const navigate = useNavigate();
    const [datasetList, setDatasetList] = useState([]);
    const [selectedDataset, setSelectedDataset] = useState(null);
    const [showDrivers, setShowDrivers] = useState([]);
    const [driverList, setDriverList] = useState([]);
    const [dependentDriver, setDependentDriver] = useState(null);
    const [independentDrivers, setIndependentDrivers] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [prognosis, setPrognosis] = useState(0);
    const [gaps, setGaps] = useState(false);
    const [breaks, setBreaks] = useState(true);
    const [error, setError] = useState(null);
    const [showNotification, setShowNotification] = useState(false);

    useEffect(()=>{
        const fetchDatasetList = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/datasets`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            if(response.status === 200){
                const data = await response.json();
                setDatasetList(data.datasets);
            }
        }
        fetchDatasetList();
    },[]);

    useEffect(()=>{
        const { dataset: dataSource, fromDate, toDate, dependentDriver, independentDrivers, prognosis } = location.state || {};
        if(datasetList.length > 0 && dataSource){
            const dataset = datasetList.find(e=>e.id===dataSource.id);
            const fillData = async ()=>{
                const response = await fetch(`${process.env.REACT_APP_API_URL}/datasets/${dataSource.id}/info`, { headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }});
                if(response.status === 200){
                    const data = await response.json();
                    const date_range = data.kpi;
                    Object.assign(dataset, {...date_range});
                }
                setSelectedDataset(dataset);
                setFromDate(fromDate);
                setToDate(toDate);
                setPrognosis(prognosis);
                const response2 = await fetch(`${process.env.REACT_APP_API_URL}/datasets/${dataset.id}/drivers?limit=100`, { headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }});
                if(response2.status === 200){
                    const data2 = await response2.json();
                    const drivers = data2.drivers;
                    setDriverList(drivers.map(e=>e.driver));
                    setDependentDriver(dependentDriver);
                    setIndependentDrivers(independentDrivers);
                }
            }
            fillData();
        }
    },[datasetList, location]);

    const handleSelectDataset = async (value) => {
        location.state = {};
        const dataset = datasetList.find(e=>e.id===value);
        if(dataset){
            const response = await fetch(`${process.env.REACT_APP_API_URL}/datasets/${dataset.id}/info`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            if(response.status === 200){
                const data = await response.json();
                let date_range;
                for (const key in data) {
                    if (data[key].hasOwnProperty('date_start')) {
                      date_range = data[key];
                      setFromDate(date_range.date_start);
                      setToDate(date_range.date_end);
                      break; 
                    }
                }
                Object.assign(dataset, {...date_range});
            }
            setSelectedDataset(dataset);
            const response2 = await fetch(`${process.env.REACT_APP_API_URL}/datasets/${dataset.id}/drivers?limit=100`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            if(response2.status === 200){
                const data2 = await response2.json();
                const drivers = data2.drivers;
                setDriverList(drivers.map(e=>e.driver));
            }
        } else {
            setDriverList([]);
        }
    }

    const handleToggle = async(driver_name) => {
        if(showDrivers.includes(driver_name)){
            setShowDrivers(showDrivers.filter(item=>item!==driver_name));
        } else{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/datasets/${selectedDataset.id}/drivers/${driver_name}?action=summary&date_from=${new Date(fromDate).toISOString().split('T')[0]}&date_to=${new Date(toDate).toISOString().split('T')[0]}`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            if(response.status === 200){
                const data = await response.json();
                if(data?.gaps === 0){
                    setGaps(false);
                } else {
                    setGaps(true);
                }
                if(data?.trend_break){
                    setBreaks(true);
                } else {
                    setBreaks(false);
                }
            }
            setShowDrivers([...showDrivers, driver_name]);
        }
    };

    const handleDateRange = (value) =>{
        if(value){
            const [from, to] = value;
            if(from && to){
                const yearsDifference = to.getFullYear() - from.getFullYear();
                if(yearsDifference < 2){
                    setError('Select at least two years.')
                    setShowNotification(true);
                } else {
                    setFromDate(new Date(from).toISOString().split('T')[0]);
                    setToDate(new Date(to).toISOString().split('T')[0]);
                }
            }
        } else {
            setFromDate("");
            setToDate("");
        }
    }
    
    const handleSelectDriver = (value)=>{
        if(value==="all"){
            setIndependentDrivers(driverList.filter(driver=>driver.id!==dependentDriver.id));
        }
        else if(value==="none"){
            setIndependentDrivers([]);
        }
        else {
            if(independentDrivers.find(e=>e.id===value)){
                setIndependentDrivers(independentDrivers.filter(elem=>elem.id!==value));
            } else {
                setIndependentDrivers([...independentDrivers, driverList.find(e=>e.id===value)]);
            }
        }
    };

    const handleRunModel = () => {
        if(!fromDate || !toDate){
            setError('Timeframe not set');
            setShowNotification(true);
        }
        else if(independentDrivers.length < 1){
            setError('Select at least one driver');
            setShowNotification(true);
        } else {
            const data = {
                title: `${fromDate}_${toDate}_${dependentDriver.name}_${new Date().getTime()}`,
                dataset: selectedDataset,
                fromDate,
                toDate,
                dependentDriver,
                independentDrivers,
                prognosis,
            }
            navigate(`/models/${data.title}`, {state: data});
        }
    }

    return (
        <Container className="px-5" fluid>
            {
                showNotification && <Notification variant='danger' message={error} onClose={() => setShowNotification(false)} duration={3000} />
            }
            <Row className="my-3">
                <Col>Solution:</Col>
                <Col>Tracker</Col>
            </Row>
            <Card className="bg-light mb-3">
                <Card.Header className="border-0 bg-transparent fw-bold">Dataset</Card.Header>
                <Card.Body>
                    <Row>
                        <Col md={3} className="mb-3">Select dataset:</Col>
                        <Col md={9}>
                            {
                                datasetList.length > 0 && <SelectPicker searchable={false} defaultValue={0} size="sm" data={datasetList.map((elem, i)=>{return{key:i+1, label: elem.name, value: elem.id}})} onChange={handleSelectDataset} style={{ minWidth: 224}} value={selectedDataset?.id} cleanable={false}/>
                            }
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className="bg-light mb-3">
                <Card.Header className="border-0 bg-transparent fw-bold">Set Timeframe</Card.Header>
                <Card.Body>
                    <Row className="mt-1 mb-2">
                        <Col md={3} className="mb-3">Select timeframe:</Col>
                        <Col md={9} className="d-flex align-items-center gap-3 mb-3">
                            {
                                selectedDataset && 
                                <DateRangePicker 
                                    size="sm"
                                    ranges={quickSelectionDateOptions(selectedDataset.date_start, selectedDataset.date_end)}
                                    format="yyyy-MM-dd"
                                    renderValue={([start, end]) => {
                                        return format(start, 'EEE, d MMM Y') + ' - ' + format(end, 'EEE, d MMM Y');
                                    }}
                                    shouldDisableDate={(date)=>{
                                        return (date < new Date(`${selectedDataset.date_start} 00:00:00`) || date > new Date(`${selectedDataset.date_end} 23:59:00`))
                                    }}
                                    value={(fromDate && toDate)?[new Date(fromDate), new Date(toDate)]:''}
                                    onChange={handleDateRange}
                                    cleanable={false}
                                />
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="mb-3">Prognosis timeframe:</Col>
                        <Col md={9}>
                            <SelectPicker searchable={false} defaultValue={0} size="sm" data={prognosisOptions} onChange={(value)=>setPrognosis(value)} style={{ minWidth: 224}} value={prognosis} cleanable={false}/>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <Card className="bg-light mb-3">
                <Card.Header className="border-0 bg-transparent fw-bold">Set Variables</Card.Header>
                <Card.Body>
                    <Row className="mb-5">
                        <Col lg={4}>What do you want to analyze:</Col>
                        <Col lg={8}>
                            <SelectPicker data={driverList.map((driver, i)=>{ return {key: i, label: driver.display_name, value: driver.id}})} size="sm" searchable={false} style={{minWidth: 224}} onChange={(value)=>setDependentDriver(driverList.find(e=>e.id===value))} value={dependentDriver?.id} cleanable={false}/>
                        </Col>
                    </Row>
                    <Row>
                        <h6>Drivers to include in analysis:</h6>
                        <div className="mx-1 mt-2">
                            <Table variant="light" hover>
                                <thead>
                                    <tr>
                                        <th>Driver</th>
                                        <th>Status</th>
                                        <th>Details</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        dependentDriver && <tr key={0}>
                                            <td colSpan={4}><span className="link" onClick={()=>handleSelectDriver("all")}>Select all</span> / <span className="link" onClick={()=>handleSelectDriver("none")}>Select none</span></td>
                                        </tr>
                                    }
                                    {
                                        driverList && driverList.map((driver, i)=>{
                                            return (
                                                <React.Fragment key={i}>
                                                    <tr className="cursor-pointer" key={i * 2 + 1}>
                                                        <td style={{ width: "34%"}}>
                                                            <Checkbox color="orange" checked={(independentDrivers.find(e=>e.id===driver.id))?true:false} onChange={() => handleSelectDriver(driver.id)} disabled={(dependentDriver && dependentDriver?.id !== driver.id) ? false : true} checkable={(dependentDriver?.id !== driver.id) ? true : false}>{driver.display_name}</Checkbox>
                                                        </td>
                                                        <td style={{ width: "33%"}}>
                                                            <FontAwesomeIcon icon={faCheck} size="xl" className="text-success" />
                                                        </td>
                                                        <td style={{ width: "33%"}}>
                                                            <Button size="sm" variant="outline-secondary" onClick={() => handleToggle(driver.name)}>{showDrivers.includes(driver.name) ? "Hide" : "Show"}</Button>
                                                        </td>
                                                    </tr>
                                                    {
                                                        selectedDataset && showDrivers && showDrivers.find(e=>e===driver.name) && 
                                                        <>
                                                            <tr key={i * 2 + 2}>
                                                                <td colSpan={3}>
                                                                    <p>
                                                                        {
                                                                            (!gaps) ? <span className="me-3"><FontAwesomeIcon color="green" icon={faCheckSquare}/> TimeSeries complete, no missing observations.</span>:<span className="me-3"><FontAwesomeIcon color="red" icon={faTimesSquare}/> TimeSeries incomplete, missing observations.</span>
                                                                        }
                                                                        {
                                                                            (!breaks) ? <span><FontAwesomeIcon color="green" icon={faCheckSquare}/> No structural breakpoints detected.</span>:<span><FontAwesomeIcon color="orange" icon={faExclamationTriangle}/> Structural breaks detected.</span>
                                                                        }
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            {
                                                                fromDate && toDate && <tr key={i * 2 + 100}>
                                                                    <td><Driver key={i} dataset={selectedDataset.id} name={driver.name} from={new Date(fromDate).toISOString().split('T')[0]} to={new Date(toDate).toISOString().split('T')[0]}/></td>
                                                                    <td><Season key={i} dataset={selectedDataset.id} name={driver.name} from={new Date(fromDate).toISOString().split('T')[0]} to={new Date(toDate).toISOString().split('T')[0]}/></td>
                                                                    <td><Break key={i} dataset={selectedDataset.id} name={driver.name} from={new Date(fromDate).toISOString().split('T')[0]} to={new Date(toDate).toISOString().split('T')[0]}/></td>
                                                                </tr>
                                                            }
                                                        </>
                                                    }
                                                </React.Fragment>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </Row>
                </Card.Body>
            </Card>
            <div className="text-end">
                <Button className="button-outline-primary" variant="outline-default" size="sm" onClick={()=>handleRunModel()} disabled={(selectedDataset && dependentDriver)?'':'disabled'}>Run Model</Button> 
            </div>
        </Container>
    );
}
export default ModelInput;