import { Button, Card, Col, Container, Modal, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faFile, faInfoCircle, faWindowMaximize } from "@fortawesome/free-solid-svg-icons";
import Plot from "react-plotly.js";
import { useEffect, useRef, useState } from "react";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

const ModelResult = ({payload, result}) => {
    const [predictedPlot, setPredictedPlot] = useState(null);
    const [featureImportance, setFeatureImportance] = useState(null);
    const [waterfallPlot, setWaterfallPlot] = useState(null);
    const [score, setScore] = useState(null);
    const [featureDescription, setFeatureDescription] = useState(null);
    const [description, setDescription] = useState(null);
    const printRef = useRef();
    // const [distribution, setDistribution] = useState(false);
    // const [value, setValue] = useState(false);
    // const [number, setNumber] = useState(false);
    // const [shap, setShap] = useState(false);

    const [show, setShow] = useState(false);
    const [graph, setGraph] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (result) {
            setPredictedPlot(JSON.parse(result.predict_plot));
            setFeatureImportance(JSON.parse(result.feature_importance));
            setWaterfallPlot(JSON.parse(result.waterfall_plot));
            setScore(result.score);
            setFeatureDescription(result.feature_description);
            setDescription(result.description);
        }
    }, [result]);
    

    const handleDownloadPdf = async () => {
        const elementsToHide = document.querySelectorAll('.no-print');
        elementsToHide.forEach(el => el.style.display = 'none');

        const element = printRef.current;
        const canvas = await html2canvas(element, { scale: 1 });

        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'pt',
            format: 'a4', 
        });

        const imgData = canvas.toDataURL('image/png');

        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = pdf.internal.pageSize.getHeight();

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);

        pdf.save(`${payload.title}.pdf`);

        elementsToHide.forEach(el => el.style.display = 'block');
    };

    return (
        <Container className="mt-3 mb-3">
            <Card className="px-5 border-0" ref={printRef}>
                <Card.Header className="border-0 bg-transparent">
                    <h5>Model results: {payload.title}</h5>
                </Card.Header>
                <Card.Body>
                    <Table>
                        <tbody>
                            <tr>
                                <td>Dataset</td>
                                <td>{payload.dataset.name}</td>
                            </tr>
                            <tr>
                                <td>Timeframe</td>
                                <td>{payload.fromDate} to {payload.toDate}</td>
                            </tr>
                            <tr>
                                <td>Prognosis</td>
                                <td>{(payload.prognosis > 0)?`${payload.prognosis} months ahead`:`None`}</td>
                            </tr>
                            <tr>
                                <td>What do you want to analyze?</td>
                                <td>{payload.dependentDriver.display_name}</td>
                            </tr>
                            <tr>
                                <td>Variables</td>
                                <td>{payload.independentDrivers.map(e=>e.display_name).join(", ")}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <Row className="mb-3">
                        <Col className="px-0">
                            <Card className="p-1 rounded-0 bg-secondary text-light">
                                <Row>
                                    <Col>Model Fit Score: </Col>
                                    <Col className="text-end">
                                        <b>{score && (score?.R2*100).toFixed(2)}%</b> <FontAwesomeIcon icon={faCircle} color="green"/> <FontAwesomeIcon icon={faInfoCircle} color="white" title={`The R-squared (R²) value of ${score?.R2} indicates that the model explains about ${(score?.R2*100).toFixed(2)}% of the variability in the dependent variable.`}/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col className="px-1">
                            <Card className="p-1 rounded-0 bg-secondary text-light">
                                <Row>
                                    <Col>Breakpoint analysis: </Col>
                                    <Col className="text-end"><b>NO ISSUES</b> <FontAwesomeIcon icon={faCircle} color="green"/> <FontAwesomeIcon icon={faInfoCircle} color="white" title=""/></Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col className="px-0 no-print">
                            <Card className="p-1 rounded-0 bg-secondary text-light">
                                <p>Previous runs: <span><a href="/models" className="text-light text-decoration-underline">Review model history</a></span></p>
                            </Card>
                        </Col>
                    </Row>
                    { 
                        predictedPlot && <Card className="mb-3">
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Prognosis</h5>
                                <Button className="ms-auto" variant="default" size="sm" onClick={()=>{setGraph("predict"); handleShow(true);}}><FontAwesomeIcon icon={faWindowMaximize}/></Button>
                            </Card.Header>
                            <Card.Body>
                                <Plot config={predictedPlot.config} data={predictedPlot.data} layout={predictedPlot.layout} className="w-100"/>
                            </Card.Body>
                            <Card.Footer className="border-0 bg-transparent my-3">
                                The data and prognosis for the selected timeframe of {payload.prognosis} months. The prognosis is based on the assumption that the chosen drivers ({payload.independentDrivers.map(e=>e.display_name).join(", ")}) maintain the average values observed in the historical data.
                            </Card.Footer>
                        </Card>
                    }
                    { 
                        featureImportance && <Card className="mb-3">
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Feature Importance</h5>
                                <Button className="ms-auto" variant="default" size="sm" onClick={()=>{setGraph("feature"); handleShow(true);}}><FontAwesomeIcon icon={faWindowMaximize}/></Button>
                            </Card.Header>
                            <Card.Body>
                                <Plot config={featureImportance.config} data={featureImportance.data} layout={featureImportance.layout} className="w-100"/>
                            </Card.Body>
                            <Card.Footer className="border-0 bg-transparent my-3">
                                {
                                    featureDescription && featureDescription
                                }
                            </Card.Footer>
                        </Card>
                    }
                    { 
                        waterfallPlot && <Card className="mb-3">
                            <Card.Header className="d-flex justify-content-between align-items-center">
                                <h5 className="mb-0">Waterfall</h5>
                                <Button className="ms-auto" variant="default" size="sm" onClick={()=>{setGraph("waterfall"); handleShow(true);}}><FontAwesomeIcon icon={faWindowMaximize}/></Button>
                            </Card.Header>
                            <Card.Body>
                                <Plot config={waterfallPlot.config} data={waterfallPlot.data} layout={waterfallPlot.layout} className='w-100'/>
                            </Card.Body>
                            <Card.Footer className="border-0 bg-transparent my-3">
                                {
                                    description && description.numbers
                                } 
                            </Card.Footer>
                        </Card>
                    }
                    {/* { 
                        description && <Card className="mb-3">
                            <Card.Header>
                                Description
                                <div className="no-print float-end">
                                    <Form.Check
                                        inline
                                        label="Distribution"
                                        type="checkbox"
                                        onChange={()=>setDistribution(!distribution)}
                                    />
                                    <Form.Check
                                        inline
                                        label="Value"
                                        type="checkbox"
                                        onChange={()=>setValue(!value)}
                                    />
                                    <Form.Check
                                        inline
                                        label="Number"
                                        type="checkbox"
                                        onChange={()=>setNumber(!number)}
                                    />
                                    <Form.Check
                                        inline
                                        label="Shap"
                                        type="checkbox"
                                        onChange={()=>setShap(!shap)}
                                    />
                                </div>
                            </Card.Header>
                            <Card.Body>
                                {
                                    description && <p>{description.default}</p>
                                }
                                {
                                    description && distribution && <p>{description.distribution_details}</p>
                                }
                                {
                                    description && value && <p>{description.values}</p>
                                }
                                {
                                    description && number && <p>{description.numbers}</p>
                                }
                                {
                                    description && shap && <p>{description.shap}</p>
                                }
                            </Card.Body>
                        </Card>
                    } */}
                </Card.Body>
                <Modal show={show} onHide={handleClose} size='xl'>
                    <Modal.Header closeButton>
                        {
                            (graph === 'predict') && <Modal.Title>Prognosis</Modal.Title>
                        }
                        {
                            (graph === 'feature') && <Modal.Title>Feature Importance</Modal.Title>
                        }
                        {
                            (graph === 'waterfall') && <Modal.Title>Waterfall</Modal.Title>
                        }
                    </Modal.Header>
                    <Modal.Body>
                    {
                        (graph === 'predict') && <Plot config={predictedPlot.config} data={predictedPlot.data} layout={predictedPlot.layout} className="w-100"/>
                    }
                    {
                        (graph === 'feature') && <Plot config={featureImportance.config} data={featureImportance.data} layout={featureImportance.layout} className="w-100"/>
                    }
                    {
                        (graph === 'waterfall') && <Plot config={waterfallPlot.config} data={waterfallPlot.data} layout={waterfallPlot.layout} className="w-100"/>
                    }
                    </Modal.Body>
                </Modal>
            </Card>
            <div className="text-end">
                <Button variant="default" className="text-primary" onClick={handleDownloadPdf}><FontAwesomeIcon icon={faFile}/> Download report in PDF format</Button>
            </div>
           </Container>
    );
}
export default ModelResult;