import './App.css';
import { Routes, Route, BrowserRouter as Router } from 'react-router-dom';
import Login from './auth/Login';
import {AuthProvider} from './context/AuthContext';
import Navigation from './components/Navigation';
import ModelInput from './components/model/Input';
import ProtectedRoute from './components/ProtectedRoute';
import Logout from './auth/Logout';
import ModelList from './components/model/List';
import ModelRun from './components/model/Run';

function App() {
  return (
      <AuthProvider>
        <Router>
          <div className="App">
            <Navigation/>
            <Routes>
              <Route path='/dashboard' element={<ProtectedRoute><ModelInput/></ProtectedRoute>}/>
              <Route path='/models' element={<ProtectedRoute><ModelList/></ProtectedRoute>}/>
              <Route path='/models/:name' element={<ProtectedRoute><ModelRun/></ProtectedRoute>}/>
              <Route path='/login' element={<Login/>}/>
              <Route path='/logout' element={<Logout/>}/>
            </Routes>
          </div>
        </Router>
      </AuthProvider>
  );
}

export default App;
