import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Alert } from "react-bootstrap";

const Notification = ({ variant, message, duration, onClose }) => {
    useEffect(() => {
      const timer = setTimeout(() => {
        onClose();
      }, duration);
  
      return () => clearTimeout(timer);
    }, [duration, onClose]);
  
    return (
        <div className="flash-message">
            <Alert key={variant} variant={variant}>
                <FontAwesomeIcon icon={faExclamationTriangle}/> {message}
            </Alert>
        </div>
    );
  }

export default Notification;