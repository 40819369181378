import { useEffect, useState } from "react";
import 'rsuite/dist/rsuite.min.css';
import { useLocation } from 'react-router-dom';
import ModelRunning from "./Running";
import ModelResult from "./Result";
import { Container } from "react-bootstrap";

const ModelRun = ()=>{
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [result, setResult] = useState(null);
    const [modelName, setModelName] = useState(null);

    useEffect(()=>{
        const { title, dataset, fromDate, toDate, dependentDriver, independentDrivers, prognosis} = location.state || {};
        setModelName(title);
        const payload = {
            title,
            date_from: fromDate,
            date_to: toDate,
            dependent_driver: dependentDriver.id,
            independent_drivers: independentDrivers.map(elem => elem.id),
            prognosis
        };
        const predict = async ()=>{
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/datasets/${dataset.id}/predict/`, {
                    method: "POST", 
                    body: JSON.stringify(payload),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    },
                });
                const result = await response.json();
                setResult(result);
            } catch (error) {
                console.error(error);
                setError(error.message);
            }
            setLoading(false);
        }
        predict();
    },[location]);
    
    return (
        <Container fluid className="px-5">
            {
                loading && <ModelRunning title={modelName} error={error}/>
            }
            {
                !loading && result && <ModelResult payload={location.state} result={result}/>
            }
        </Container>
    );
}
export default ModelRun;