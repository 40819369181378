import { Card, Col, Image, Row, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationTriangle, faSpinner, faTimes } from "@fortawesome/free-solid-svg-icons";
import spinner from '../../assets/spinner.gif';
import { useEffect, useState } from "react";

const ModelRunning = ({title, error})=>{
    const [step1, setStep1] = useState(false);
    const [step2, setStep2] = useState(false);
    const [step3, setStep3] = useState(false);
    const [step4, setStep4] = useState(false);

    useEffect(()=>{
        setTimeout(()=>{
            setStep1(true);
        },500);
        setTimeout(()=>{
            setStep2(true);
        },1000);
        setTimeout(()=>{
            setStep3(true);
        },3000);
        setTimeout(()=>{
            setStep4(true);
        },5000);
    },[]);

    return (
        <div>
           <Card className="border-0">
                <Card.Header className="border-0 bg-transparent">
                    <h4>Running model: {title}</h4>
                </Card.Header>
                <Card.Body>
                    <Row>
                        <Col>
                            <Table>
                                {
                                    !error && <tbody>
                                        <tr>
                                            <td>Running preliminary analysis...</td>
                                            <td><FontAwesomeIcon icon={step1 ? faCheckCircle:faSpinner} className="text-success"/></td>
                                        </tr>
                                        <tr>
                                            <td>Calculating model fit...</td>
                                            <td><FontAwesomeIcon icon={step2 ? faCheckCircle:faSpinner} className="text-success"/></td>
                                        </tr>
                                        <tr>
                                            <td>Building visualizations...</td>
                                            <td><FontAwesomeIcon icon={step3 ? faCheckCircle:faSpinner} className="text-success"/></td>
                                        </tr>
                                        <tr>
                                            <td>Summarizing results...</td>
                                            <td><FontAwesomeIcon icon={step4 ? faCheckCircle:faSpinner} className="text-success"/></td>
                                        </tr>
                                    </tbody>
                                }{
                                    error && <tbody>
                                        <tr>
                                            <td>Running preliminary analysis...</td>
                                            <td><FontAwesomeIcon icon={step1 ? faExclamationTriangle:faSpinner} className="text-warning"/></td>
                                        </tr>
                                        <tr>
                                            <td>Calculating model fit...</td>
                                            <td><FontAwesomeIcon icon={step2 ? faExclamationTriangle:faSpinner} className="text-warning"/></td>
                                        </tr>
                                        <tr>
                                            <td>Building visualizations...</td>
                                            <td><FontAwesomeIcon icon={step3 ? faTimes:faSpinner} className="text-danger"/></td>
                                        </tr>
                                        <tr>
                                            <td>Summarizing results...</td>
                                            <td><FontAwesomeIcon icon={step4 ? faTimes:faSpinner} className="text-danger"/></td>
                                        </tr>
                                    </tbody>
                                }
                            </Table>
                        </Col>
                        <Col className="text-center">
                        {
                            !error && <Image src={spinner} width={150}/>
                        }
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </div>
    );
}
export default ModelRunning;