import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const storedUser = localStorage.getItem('username');
    const storedToken = localStorage.getItem('token');

    if (storedUser && storedToken) {
      setUser(storedUser);
      setIsAuthenticated(true);
    }
    setIsLoading(false); 
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if(token){
      const validateToken = async()=>{
        const response = await fetch(`${process.env.REACT_APP_API_URL}/token/validate/`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({token}),
        });
        if(response.status === 200){
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      }
      validateToken();
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const login = (userData) => {
    localStorage.setItem('username', userData.username);
    localStorage.setItem('token', userData.access_token);
    setIsAuthenticated(true);
  };

  const logout = (return_to='') => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    if(return_to){
      window.location.href = return_to;
    }else{
      window.location.href = window.location.origin+'/login';
    }
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, user, login, logout, isLoading }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
