import { useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import {useAuth} from '../context/AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignIn } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const Login = ()=>{
    const {login} = useAuth();
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async(e)=>{
        e.preventDefault();
        const response = await fetch(`${process.env.REACT_APP_API_URL}/token/`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(formData),
        });
        if(response.status !== 200){
            setError(response.statusText);
        } else {
            const data = await response.json();
            login({username: formData.username, access_token: data.access});
            navigate('/dashboard');
        }
    }
    return (
        <Container className="mt-5">
            <Card style={{width: "420px", margin: "auto"}}>
                <Card.Body>
                    <Card.Title className="text-center">Login - Opinion Samtidsdata</Card.Title>
                    { error && <p className="text-center text-danger">{error}</p>}
                    <Form className="mt-3" method="POST" onSubmit={handleSubmit}>
                        <div className="form-group mb-3">
                            <Form.Label>Username</Form.Label>
                            <Form.Control type="text" name="username" onChange={handleChange} required/>
                        </div>
                        <div className="form-group mb-3">
                            <Form.Label>Password</Form.Label>
                            <Form.Control type="password" name="password" onChange={handleChange} required/>
                        </div>
                        <div className="d-grid">
                            <Button type="submit" variant="default" className="button-outline-primary"><FontAwesomeIcon icon={faSignIn}/> Login</Button>
                        </div>
                    </Form>
                </Card.Body>
            </Card>
        </Container>
    );
}
export default Login;