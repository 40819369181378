import { faEdit, faRedo, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Button, Container, Pagination, Table } from "react-bootstrap";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Notification from "../Notification";
import { SelectPicker } from "rsuite";

const ModelList = () => {
    const [datasetList, setDatasetList] = useState([]);
    const [selectedDataset, setSelectedDataset] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [artifacts, setArtifacts] = useState([]);
    const [paginationItems, setPaginationItems] = useState([]);
    const [lastPage, setLastPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(new Date().getTime());
    const [notification, setNotification] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const navigate = useNavigate();

    const handleChangePage = (page) => {
        setCurrentPage(page);
        setSearchParams({'page': page});
    }

    useEffect(()=>{
        const fetchDatasetList = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/datasets`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            if(response.status === 200){
                const data = await response.json();
                setDatasetList(data.datasets);
            }
        }
        fetchDatasetList();
    },[]);

    useEffect(()=>{
        const fetchArtifacts = async (pageNo) => {
            setLoading(true);
            let url = `${process.env.REACT_APP_API_URL}/artifacts?page=${pageNo}&limit=${10}`;
            if(selectedDataset){
                url+=`&filter_by=dataset&dataset=${selectedDataset}`;
            }
            const response = await fetch(url, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            if(response.status === 200){
                const result = await response.json();
                setLoading(false);
                setArtifacts(result.artifacts);
                setLastPage(result.last_page);
                let pagination_items = [];
                for (let i = 1; i <= result.last_page; i++) {
                    pagination_items.push(
                        <Pagination.Item key={i} active={i === pageNo} onClick={()=>{setCurrentPage(i);}}>{i}</Pagination.Item>
                    );
                }
                setPaginationItems(pagination_items);
            }
        }
        if(searchParams.get('page')){
            setCurrentPage(+searchParams.get('page'));
            fetchArtifacts(+searchParams.get('page'));
        } else{
            fetchArtifacts(1);
        }
    },[refresh, currentPage, searchParams, selectedDataset]);

    const handleDelete = async(id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/artifacts/${id}`, { 
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        if(response.status === 200){
            setRefresh(new Date().getTime());
            setNotification("Model result deleted successfully");
            setShowNotification(true);
        }
    }

    const handleRerun = (title, dataset, fromDate, toDate, dependentDriver, independentDrivers, prognosis) => {
        const data = {
            title,
            dataset,
            fromDate,
            toDate,
            dependentDriver,
            independentDrivers,
            prognosis,
        }
        navigate(`/models/${data.title}`, {state: data});
    }

    const handleModifyAndRun = (title, dataset, fromDate, toDate, dependentDriver, independentDrivers, prognosis) => {
        const data = {
            title,
            dataset,
            fromDate,
            toDate,
            dependentDriver,
            independentDrivers,
            prognosis,
        }
        navigate(`/dashboard`, {state: data});
    }

    return (
        <Container fluid className="px-5 mt-3">
            {
                showNotification && <Notification variant='success' message={notification} onClose={() => setShowNotification(false)} duration={3000} />
            }
            <h5>Recent model results</h5>
            {
                datasetList.length > 0 && <div className="text-end"><SelectPicker searchable={false} defaultValue={0} size="sm" data={datasetList.map((elem, i)=>{return{key:i+1, label: elem.name, value: elem.id}})} onChange={(value)=>setSelectedDataset(value)} style={{ minWidth: 224}} value={selectedDataset}/></div>
            }
            <Table bordered className="mt-3">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Solution</th>
                        <th>Timeframe</th>
                        <th>Prognosis</th>
                        <th>Dependent Variable</th>
                        <th>Independent Variables</th>
                        <th>Fit score</th>
                        <th>Action</th>
                    </tr>
                </thead>    
                <tbody>
                    {
                        !loading && artifacts && artifacts.map((elem,i)=>{
                            return (
                                <tr key={i+1}>
                                    <td style={{width: "10%"}}>{elem.date}</td>
                                    <td style={{width: "10%"}}>Tracker</td>
                                    <td style={{width: "18%"}}>{elem.from_date} to {elem.to_date}</td>
                                    <td style={{width: "10%"}}>{(elem.prognosis > 0)?`${elem.prognosis} months`:`None`}</td>
                                    <td style={{width: "10%"}}>{elem.dependent_driver.display_name}</td>
                                    <td style={{width: "20%"}}>{elem.independent_drivers.map(e=>e.driver.display_name).join(", ")}</td>
                                    <td style={{width: "10%"}}>{(elem.fit_score*100).toFixed(2)}%</td>
                                    <td style={{width: "12%"}}>
                                        <Button size="sm" variant="primary" className="me-2" title="Modify and run" onClick={()=>handleModifyAndRun(elem.title, elem.dataset, elem.from_date, elem.to_date, elem.dependent_driver, elem.independent_drivers.map(e=>e.driver), elem.prognosis)}><FontAwesomeIcon icon={faEdit}/></Button>
                                        <Button size="sm" variant="dark" className="me-2" title="Re-run" onClick={()=>handleRerun(elem.title, elem.dataset, elem.from_date, elem.to_date, elem.dependent_driver, elem.independent_drivers.map(e=>e.driver), elem.prognosis)}><FontAwesomeIcon icon={faRedo}/></Button>
                                        <Button size="sm" variant="danger" title="Delete" onClick={()=>handleDelete(elem.id)}><FontAwesomeIcon icon={faTrash}/></Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </Table>
            {
                paginationItems && paginationItems.length > 1 && <Pagination>
                    <Pagination.First onClick={()=>handleChangePage(1)}/>
                    <Pagination.Prev onClick={()=>{if(currentPage > 1){handleChangePage(currentPage-1)}}}/>
                        {paginationItems}
                    <Pagination.Next onClick={()=>{if(currentPage < lastPage){handleChangePage(currentPage+1)} }}/>
                    <Pagination.Last onClick={()=>handleChangePage(lastPage)}/>
                </Pagination>
            }
        </Container>
    );
}
export default ModelList;