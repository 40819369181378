import logo from '../assets/logo.png';
import { Container, Nav, Navbar } from "react-bootstrap";
import {useAuth} from '../context/AuthContext';
import { Link, useLocation } from 'react-router-dom';

const Navigation = ()=>{
    const location = useLocation(); 
    const currentPath = location.pathname; 
    const { isAuthenticated } = useAuth();
    
    return (
        <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
                <Navbar.Brand as={Link} to="/dashboard"><img className='logo' src={logo} alt='logo'/><span className='logo-text'>Samtidsdata</span></Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Nav className="me-auto">
                        {
                            isAuthenticated && <>
                                <Nav.Link as={Link} to="/dashboard" className={currentPath === "/dashboard" ? "active" : ""}>Home</Nav.Link>
                                <Nav.Link as={Link} to="/models" className={currentPath.includes("/models") ? "active" : ""}>Models</Nav.Link>
                                {/* <Nav.Link as={Link} to="/scenario" className={currentPath === "/scenario" ? "active" : ""}>Scenarios</Nav.Link> */}
                                <Nav.Link as={Link} to="/logout">Logout</Nav.Link>
                            </>
                        }
                        {
                            !isAuthenticated && <Nav.Link as={Link} to="/login">Login</Nav.Link>
                        }
                    </Nav>
                    {
                        isAuthenticated && <Navbar.Text>
                            Signed in as: {localStorage.getItem('username')}
                        </Navbar.Text>
                    }
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}
export default Navigation;